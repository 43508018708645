import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import Button from '@nubank/nuds-web/components/Button/Button';
import Checkbox from '@nubank/nuds-web/components/Checkbox/Checkbox';
import Snackbar from '@nubank/nuds-web/components/Snackbar/Snackbar';
import Box from '@nubank/nuds-web/components/Box/Box';

import { trackFieldFilling } from 'tracking/application/form';
import { getProspectType } from 'utils/prospectTypes';
import RegistrationRealtimeLoader from 'patterns/RegistrationRealtimeLoader/RegistrationRealtimeLoader';
import NuDSFormBR from 'components/NuDSFormBR/NuDSFormBR';
import { getFieldProperties, normalizeFieldValidations } from 'patterns/ShortForm/utils';
import { ERROR_MESSAGES } from 'patterns/ShortForm/constants';
import { useExperimentInfo } from '@nubank/www-latam-commons/components/Experiment/ExperimentsContext';
import { PHONE_REMINDER } from 'utils/experiments';

const trackField = (valueKey, prospectType) => fieldName => event => {
  if (event.target[valueKey]) {
    trackFieldFilling({
      prospectType,
      field: `${fieldName}_short`,
    });
  }
};

const DEFAULT_INITIAL_VALUES = {
  name: '',
  cpf: '',
  email: '',
  emailConfirmation: '',
  accepted: false,
  phone: '',
};

const FIELDS_ORDER_DEFAULT = {
  email: [
    'email',
    'emailConfirmation',
    'name',
    'cpf',
  ],

  name: [
    'name',
    'cpf',
    'email',
    'emailConfirmation',
  ],

  cpf: [
    'cpf',
    'name',
    'email',
    'emailConfirmation',
  ],
};

const FIELDS_ORDER_WITH_PHONE_REMINDER = {
  email: [
    'email',
    'emailConfirmation',
    'name',
    'phone',
    'cpf',
  ],

  name: [
    'name',
    'cpf',
    'phone',
    'email',
    'emailConfirmation',
  ],

  cpf: [
    'cpf',
    'name',
    'phone',
    'email',
    'emailConfirmation',
  ],
};

function CompleteFormVertical({
  formTitle,
  formVerticalTitle,
  formVerticalContent,
  formVerticalFeedback,
  formVerticalOnSubmit,
  fromInviter,
  initialField,
  initialTouched,
  initialValues,
  prospectTypeKey,
  formVerticalContentBackgroundImage,
  formPreContent,
}) {
  const { formatMessage } = useIntl();
  const { variant } = useExperimentInfo(PHONE_REMINDER.id);
  const FIELDS_ORDER = variant === PHONE_REMINDER.variantA
    ? FIELDS_ORDER_WITH_PHONE_REMINDER
    : FIELDS_ORDER_DEFAULT;

  const [realtimeResult, setRealtimeResult] = useState(false);

  const prospectType = getProspectType(prospectTypeKey);

  const createTextFieldTracker = useCallback(trackField('value', prospectType.type), [prospectType]);
  const createCheckboxTracker = useCallback(trackField('checked', prospectType.type), [prospectType]);

  const handleSubmitCompose = args => {
    const params = {
      ...args,
      fromInviter,
      prospectType,
      formatMessage,
      setRealtimeResult,
    };
    return formVerticalOnSubmit(params);
  };

  const FormVerticalFeedback = formVerticalFeedback;
  const FormVerticalContent = formVerticalContent;

  return (
    <NuDSFormBR>
      <NuDSFormBR.Step
        onSubmit={(...args) => {
          if (args[0].values.phone) { window.sessionStorage.setItem('phone', args[0].values.phone); }
          handleSubmitCompose(...args);
        }}
        initialValues={{
          ...DEFAULT_INITIAL_VALUES,
          ...initialValues,
        }}
        initialTouched={initialTouched}
      >
        {({
          isDisabled,
          isSubmitting,
          formErrorMsg,
          clearFormErrorMsg,
        }) => (
          <>
            <Box
              display={{ lg: 'grid' }}
              gridColumnGap={{ lg: '8x' }}
              gridTemplateColumns={{ lg: 'repeat(2,1fr)' }}
              minHeight="100vh"
            >
              <Box
                paddingVertical="20x"
                paddingLeft={{ xs: '6x', md: '12x', lg: 56 }}
                display={{ xs: 'none', lg: 'grid' }}
                gridColumnGap={{ lg: '8x' }}
                gridTemplateColumns="repeat(6,1fr)"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundColor={formVerticalContentBackgroundImage?.backgroundColor
                  ? formVerticalContentBackgroundImage.backgroundColor
                  : 'white.dark'}
                backgroundImage={formVerticalContentBackgroundImage?.srcSet}
              >
                <Box
                  gridColumn="1 / span 5"
                  display="flex"
                  flexDirection="column"
                  justifyContent={{ lg: 'space-between' }}
                  alignItems={{ lg: 'center' }}
                >
                  {formVerticalTitle && (
                    <Typography
                      variant="heading2"
                      tag="h3"
                      color="black"
                      colorVariant="default"
                      intlKey={formVerticalTitle}
                      marginBottom="8x"
                    />
                  )}
                  <FormVerticalContent />
                </Box>
              </Box>
              <Box
                paddingVertical="20x"
                paddingLeft={{ xs: '6x', md: '12x', lg: 0 }}
                paddingRight={{ xs: '6x', md: '12x', lg: 56 }}
                display={{ lg: 'grid' }}
                gridColumnGap={{ lg: '8x' }}
                gridTemplateColumns="repeat(6,1fr)"
              >
                <Box
                  gridColumn="2 / span 4"
                  display="flex"
                  flexDirection="column"
                  justifyContent={{ lg: 'space-between' }}
                >
                  {formPreContent && formPreContent()}
                  <Box display={{ xs: 'block', lg: 'none' }}>
                    {formTitle && (
                      <Typography
                        variant="heading2"
                        tag="h3"
                        color="black"
                        colorVariant="default"
                        intlKey={formTitle}
                        marginBottom="8x"
                      />
                    )}
                  </Box>

                  {FIELDS_ORDER[initialField].map(fieldName => {
                    const fieldProps = getFieldProperties(fieldName);
                    const fieldTrackingFn = initialField === fieldName
                      ? () => { }
                      : createTextFieldTracker(fieldName);

                    return (
                      <TextField
                        key={fieldName}
                        id={`field-${fieldName}`}
                        name={fieldName}
                        autoComplete="off"
                        type={fieldProps.type}
                        onBlur={fieldTrackingFn}
                        label={formatMessage({ id: fieldProps.label })}
                        syncValidations={
                          normalizeFieldValidations(fieldProps.validations, formatMessage)
                        }
                      />
                    );
                  })}

                  <Checkbox
                    id="field-accepted"
                    name="accepted"
                    onChange={createCheckboxTracker('privacyNotice')}
                    syncValidations={{
                      required: formatMessage({ id: ERROR_MESSAGES.accepted }),
                    }}
                    label={(
                      <Typography
                        variant="subtitle1"
                        tag="span"
                        color="black"
                        colorVariant="default"
                        intlKey="COMMON.APPLICATION.LABEL.PRIVACY_POLICY"
                        intlValues={{
                          a: content => (
                            <Link
                              href="/contrato/politica-privacidade"
                              color="primary"
                              target="_blank"
                            >
                              {content}
                            </Link>
                          ),
                        }}
                      />
                    )}
                  />

                  <Button
                    extended
                    type="submit"
                    variant="contained"
                    styleVariant="primary"
                    disabled={isDisabled || isSubmitting}
                    intlKey="COMMON.WIDGET.SHORT_FORM.COMPLETE_BUTTON"
                  />
                </Box>
              </Box>
            </Box>

            <Snackbar
              visible={Boolean(formErrorMsg)}
              onActionClick={clearFormErrorMsg}
              actionText="Fechar"
            >
              {formErrorMsg}
            </Snackbar>
          </>
        )}
      </NuDSFormBR.Step>

      <NuDSFormBR.Step isForm={false}>
        {() => (
          <Box height="calc(100vh - 5rem)">
            <RegistrationRealtimeLoader isOpenForm />
          </Box>
        )}
      </NuDSFormBR.Step>

      <NuDSFormBR.Step isForm={false}>
        {({ formsValues }) => (
          <FormVerticalFeedback
            prospectEmail={formsValues.email}
            realtimeResult={realtimeResult}
          />
        )}
      </NuDSFormBR.Step>
    </NuDSFormBR>
  );
}

/*
** TODO: Get rid of this props, we wouldn't depend upon them to exercise the
** form fields, we could use the userEvent utility from react-testing-library
** instead
*/
CompleteFormVertical.defaultProps = {
  fromInviter: false,
  initialTouched: {},
  initialValues: DEFAULT_INITIAL_VALUES,
  formPreContent: undefined,
  formVerticalContentBackgroundImage: undefined,
  closeButton: true,
};

export const CompleteFormVerticalPropTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  closeButton: PropTypes.bool,
  formPreContent: PropTypes.func,
  formTitle: PropTypes.string.isRequired,
  formVerticalContent: PropTypes.elementType.isRequired,
  formVerticalContentBackgroundImage: PropTypes.shape({
    backgroundColor: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
      xl: PropTypes.string,
      xs: PropTypes.string,
    }),
  }),
  formVerticalFeedback: PropTypes.elementType.isRequired,
  formVerticalOnSubmit: PropTypes.func.isRequired,
  formVerticalTitle: PropTypes.string.isRequired,
  fromInviter: PropTypes.bool,
  initialField: PropTypes.string.isRequired,
  initialTouched: PropTypes.shape({
    accepted: PropTypes.bool,
    cpf: PropTypes.bool,
    email: PropTypes.bool,
    emailConfirmation: PropTypes.bool,
    name: PropTypes.bool,
    phone: PropTypes.bool,
  }),
  initialValues: PropTypes.shape({
    accepted: PropTypes.bool,
    cpf: PropTypes.string,
    email: PropTypes.string,
    emailConfirmation: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
  prospectTypeKey: PropTypes.string.isRequired,
};

CompleteFormVertical.propTypes = CompleteFormVerticalPropTypes;

export default React.memo(CompleteFormVertical);
