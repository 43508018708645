import React from 'react';

import FormImages from 'patterns/ApplicationFormDrawer/components/FormImages/FormImages';
import RegistrationStatus from 'patterns/RegistrationStatus/RegistrationStatus';
import registerProspectSubmit from 'patterns/ApplicationFormDrawer/registerPropesctSubmit';
import { MULTI_PRODUCT } from 'utils/prospectTypes';
import { ApplicationFormDrawer } from 'patterns/ApplicationFormDrawer/ApplicationFormDrawer';
import ApplicationFormWithProductChoiceDrawer from 'patterns/ApplicationFormWithProductChoiceDrawer/ApplicationFormWithProductChoiceDrawer';
import ApplicationFormContextProvider from 'patterns/ApplicationFormDrawer/ApplicationFormContext';
import Experiment from '@nubank/www-latam-commons/components/Experiment/Experiment';
import { PRODUCT_CHOICE_EXPERIMENT } from 'utils/experiments';

const defaultFormProps = {
  closeButton: true,
  formTitle: 'SHORT_FORM.MULTI_PRODUCT.COMPLETE_FORM_TITLE',
  formVerticalTitle: 'SHORT_FORM.MULTI_PRODUCT.COMPLETE_FORM_VERTICAL_TITLE',
  formVerticalContent: FormImages,
  formVerticalFeedback: RegistrationStatus,
  formVerticalOnSubmit: registerProspectSubmit,
  initialField: 'cpf',
  initialTouched: { cpf: false },
  prospectTypeKey: MULTI_PRODUCT,
  fromInviter: false,
};

/*
  Configure application form for the page.

  @param WrappedComponent Component to wrap with this hoc
  @param {CompleteFormVertical.propTypes} Props to be passed to CompleteFormVertical component
*/
const withApplicationFormDrawer = (WrappedComponent, formProps) => {
  const mergedFormProps = { ...defaultFormProps, ...formProps };

  const ApplicationFormWrapper = props => (
    <ApplicationFormContextProvider
      prospectTypeKey={mergedFormProps.prospectTypeKey}
      initialField={mergedFormProps.initialField}
      drawerInitialState={mergedFormProps.drawerInitialState}
    >
      <WrappedComponent {...props} />
      <Experiment id={PRODUCT_CHOICE_EXPERIMENT.id}>
        <Experiment.Original>
          <ApplicationFormDrawer
            formProps={mergedFormProps}
          />
        </Experiment.Original>
        <Experiment.Variant id={PRODUCT_CHOICE_EXPERIMENT.variants}>
          <ApplicationFormWithProductChoiceDrawer />
        </Experiment.Variant>
      </Experiment>
    </ApplicationFormContextProvider>
  );

  ApplicationFormWrapper.getInitialProps = async ctx => {
    let pageProps = {};

    if (WrappedComponent.getInitialProps) {
      pageProps = await WrappedComponent.getInitialProps(ctx);
    }

    return {
      ...pageProps,
    };
  };

  return ApplicationFormWrapper;
};

export default withApplicationFormDrawer;
