import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import FeedbackEmail from './components/FeedbackEmail/FeedbackEmail';

const FeedbackProductApprovedMGM = dynamic(() => import('./components/FeedbackProductApproved/FeedbackProductApprovedMGM'));

function RegistrationStatus({
  prospectEmail,
  realtimeResult,
}) {
  const { analysisResult, prospect } = realtimeResult;

  const template = analysisResult?.template;
  const hasBeenApproved = Boolean(template);

  return hasBeenApproved ? (
    <FeedbackProductApprovedMGM
      approvalTemplate={template}
      prospect={prospect}
    />
  ) : (
    <FeedbackEmail email={prospectEmail} />
  );
}

RegistrationStatus.propTypes = {
  prospectEmail: PropTypes.string.isRequired,
  realtimeResult: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      analysisResult: PropTypes.oneOfType([
        PropTypes.shape({
          template: PropTypes.shape({
            id: PropTypes.string,
          }),
        }),
        PropTypes.bool,
      ]),
      prospect: PropTypes.shape({
        marketingId: PropTypes.string.isRequired,
        prospectType: PropTypes.string.isRequired,
      }),
    }),
  ]).isRequired,
};

export default React.memo(RegistrationStatus);
