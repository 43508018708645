import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';

import { StyledIcon } from './styles/StyledIcon';
import { StyledTypography, StyledTypographyEmail } from './styles/StyledTypography';

function FeedbackEmail({ email }) {
  return (
    <Box
      width="100%"
      height="calc(100vh - 5rem)"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <StyledIcon
        color="primary"
        ariaHidden="true"
        name="check-circle"
      />

      <Typography
        marginBottom="4x"
        variant="heading4"
        color="black"
        colorVariant="light"
        textAlign="center"
        intlKey="COMMON.APPLICATION.FEEDBACK.EMAIL.TITLE"
      />

      <StyledTypography
        variant="subtitle1"
        color="black"
        textAlign="center"
        intlKey="COMMON.APPLICATION.FEEDBACK.EMAIL.DESCRIPTION"
        intlValues={{
          email: (
            <StyledTypographyEmail
              tag="span"
              variant="subtitle1"
              color="primary"
            >
              {email}
            </StyledTypographyEmail>
          ),
        }}
      />
    </Box>
  );
}

FeedbackEmail.propTypes = {
  email: PropTypes.string.isRequired,
};

export default React.memo(FeedbackEmail);
