import compose from '@nubank/nuds-web/utils/functional/compose';
import { strictlyMergeMatchingProperties } from '@nubank/nuds-web/utils/object/mergeMatchingProperties';

import fromEntries from '@nubank/www-latam-commons/utils/object/fromEntries';
import registerProspect from 'domains/prospect/registerProspect';
import { trackFormSubmission } from 'tracking/application/form';
import { sentryException, ERROR_SEVERITY } from 'services/errorTracking/sentryException';
import { ERROR_MESSAGES } from 'patterns/ShortForm/constants';

/*
** TODO: If the experiment turns out to be successful, we need to create a way
** to share this behavior with the other application forms.
*/
const registerProspectSubmit = async ({
  values,
  nextStep,
  goToStep,
  setFormErrorMsg,
  setFormErrors,
  setSubmitting,
  fromInviter,
  prospectType: prospectTypeValue,
  prospectTypeFormValue,
  formatMessage,
  setRealtimeResult,
}) => {
  const { name, cpf, email } = values;
  const prospect = { name, cpf, email };
  const STEP_FEEDBACK_INDEX = 2;

  const prospectType = prospectTypeFormValue || prospectTypeValue;

  trackFormSubmission({ prospectType: prospectTypeValue.type });

  const displayEmailFeedback = () => {
    setSubmitting(false);
    goToStep(STEP_FEEDBACK_INDEX);
  };

  try {
    await registerProspect({
      prospect,
      prospectType,
      prospectTypeTracking: prospectTypeValue,
      fromInviter,
      onThrottledResponse: displayEmailFeedback,
      onApplicationFinish: () => {
        displayEmailFeedback();
      },
      onAnalysisStart() {
        setSubmitting(false);
        nextStep();
      },
      onAnalysisFinish(analysisResult) {
        setRealtimeResult(analysisResult);
        goToStep(STEP_FEEDBACK_INDEX);
      },
    });
  } catch (errors) {
    setSubmitting(false);
    const { generic, ...fields } = errors;

    const fieldsErrors = fromEntries(
      Object
        .entries(strictlyMergeMatchingProperties(fields, ERROR_MESSAGES))
        .map(([fieldName, messageId]) => [fieldName, formatMessage({ id: messageId })]),
    );

    const isAnyFieldWithError = Boolean(Object.keys(fieldsErrors).length);

    if (isAnyFieldWithError) {
      setFormErrors(fieldsErrors);
    }

    if (generic || !isAnyFieldWithError) {
      compose(
        setFormErrorMsg,
        formatMessage,
      )({ id: ERROR_MESSAGES.generic });
    }

    sentryException({
      error: errors,
      flow: 'application_flow',
      checkpoint: 'register_prospect',
      namespace: '<CompleteFormVertical/>',
      level: ERROR_SEVERITY.CRITICAL,
    });
  }
};

export default registerProspectSubmit;
