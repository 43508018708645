import { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { isBrowserEnv } from '@nubank/www-latam-commons/utils/env/isBrowserEnv';

function DrawerPortal({ children }) {
  if (!isBrowserEnv) return null;

  let modalRoot = document.getElementById('drawer-root');
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'drawer-root');
    document.body.appendChild(modalRoot);
  }

  const drawerWrapperRef = useRef();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    drawerWrapperRef.current = document.createElement('div');
    modalRoot.appendChild(drawerWrapperRef.current);
    setMounted(true);

    return () => {
      modalRoot.removeChild(drawerWrapperRef.current);
    };
  }, []);

  return mounted ? ReactDOM.createPortal(children, drawerWrapperRef.current) : null;
}

export default DrawerPortal;
