import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const rem = 16;

const variants = {
  xs: {
    heading2: { fontSize: 1.75, lineHeight: 1.16 },
    heading4: { fontSize: 1.25, lineHeight: 1.25 },
  },
  md: {
    heading2: { fontSize: 3, lineHeight: 1.16 },
    heading4: { fontSize: 1.5, lineHeight: 1.32 },
  },
};

const highlightColors = {
  purple: '138, 5, 190',
  white: '255, 255, 255',
};

const skeletonShineKeyframes = (lines, space) => (keyframes`
to {
  background-position:
  300% 0, /* move highlight to right */
  ${Array.from(Array(lines).keys()).map(i => (`0 calc(${i} * 1em + ${space / 2}px + 0.1em + ${i} * ${space}px)`)).join(', ')};
}
`);

const createSkeleton = ({ fontSize = 1, lineHeight = 1.16 }, highlightColor = 'purple', lines = 4) => {
  const space = Math.floor(rem * fontSize * (lineHeight - 1));
  const hlColor = highlightColors[highlightColor];

  return css`
    width: 100%;
    height: calc(1em * ${lines} + ${space}px * ${lines});
    font-size: ${fontSize}rem;
    margin-bottom: ${space}px;

    background-image: linear-gradient(
        100deg,
        rgba(${hlColor}, 0) 20%,
        rgba(${hlColor}, 0.5) 50%,
        rgba(${hlColor}, 0) 70%
      ),
      ${Array.from(Array(lines).keys()).map(() => ('linear-gradient(#ddd 0.8em, transparent 0)')).join(', ')};

    background-repeat: no-repeat;

    background-size:
      25% 300%, /* highlight */
      70% 0.8em,
      100% 0.8em,
      100% 0.8em,
      80% 0.8em;

    background-position:
      0 , /* highlight */
      ${Array.from(Array(lines).keys()).map(i => (`0 calc(${i} * 1em + ${space / 2}px + 0.1em + ${i} * ${space}px)`)).join(', ')};

    animation: ${skeletonShineKeyframes(lines, space)} 1s linear infinite;
  `;
};

export const SkeletonForTypography = styled(Box)`
  ${props => breakpointsMedia({
    xs: createSkeleton(
      variants.xs[props.$variant],
      props.$highlightColor,
      props.$lines?.xs || props.$lines,
    ),
    md: createSkeleton(
      variants.md[props.$variant],
      props.$highlightColor,
      props.$lines?.md || props.$lines,
    ),
  })}
`;

SkeletonForTypography.propTypes = {
  highlightColor: PropTypes.string,
};
